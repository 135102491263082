import { createSlice } from '@reduxjs/toolkit/react';
import { storeLogout } from '@perpay-web/authentication/actions';

const supportToolSlice = createSlice({
    name: 'support-tool',
    initialState: {
        supportToolIntercomBucket: null,
        supportToolInternallyOpened: false,
        supportToolOpen: false,
        loadedSupportTool: false,
    },
    reducers: (create) => ({
        launch: create.preparedReducer(
            (internallyOpened = false, intercomBucket = null) => ({
                payload: { internallyOpened, intercomBucket },
            }),
            (state, action) => ({
                ...state,
                supportToolIntercomBucket: action.payload.intercomBucket,
                supportToolInternallyOpened: action.payload.internallyOpened,
                supportToolOpen: true,
            }),
        ),
        close: create.reducer((state) => ({
            ...state,
            supportToolIntercomBucket: null,
            supportToolInternallyOpened: false,
            supportToolOpen: false,
        })),
        loaded: create.reducer((state) => ({
            ...state,
            loadedSupportTool: true,
            // reset the open state if the tool is reloaded
            supportToolInternallyOpened: false,
            supportToolOpen: false,
        })),
        analyticsSelfHelpOpened: create.reducer((state) => state),
    }),
    extraReducers: (builder) => {
        builder.addCase(storeLogout, (state) => ({
            ...state,
            loadedSupportTool: true,
        }));
    },
});

export const getSupportToolIntercomBucket = (state) =>
    state.supportTool.supportToolIntercomBucket || null;

export const getSupportToolInternallyOpened = (state) =>
    state.supportTool.supportToolInternallyOpened || false;

export const getSupportToolOpen = (state) =>
    state.supportTool.supportToolOpen || false;

export const getLoadedSupportTool = (state) =>
    state.supportTool.loadedSupportTool || false;

export const {
    launch: launchSupportTool,
    close: closeSupportTool,
    loaded: loadedSupportTool,
    analyticsSelfHelpOpened,
} = supportToolSlice.actions;
export default supportToolSlice.reducer;
