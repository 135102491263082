import { normalize } from 'normalizr';
import { ofType } from 'redux-observable';
import {
    exhaustMap,
    mergeMap,
    switchMap,
    withLatestFrom,
} from 'rxjs/operators';

import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { emitOrTimeout } from '@perpay-web/utils/observable';
import {
    BACKEND_FETCH_JOBS,
    BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID,
} from '@perpay-web/fintech/constants/actionTypes';
import { JOBS } from '@perpay-web/fintech/constants/tableNames';
import { JOBS_ENDPOINT } from '@perpay-web/fintech/constants/urls';
import {
    checkoutUpdateEIDError,
    checkoutNextStep,
} from '@perpay-web/fintech/actions/ui/checkout';
import { getPrimaryJobUUID } from '@perpay-web/fintech/selectors/authentication';
import { job } from '@perpay-web/fintech/normalizers/schemas';
import {
    replaceJobs,
    fetchJobsError,
    fetchJobsForDataModule as fetchJobsForDataModuleAction,
    fetchJobsSuccess,
    fetchJobsError as fetchJobsErrorForDataModule,
    createPrimaryJob as createPrimaryJobAction,
    createPrimaryJobSuccess,
    createPrimaryJobError,
    updateJob as updateJobAction,
    updateJobSuccess,
    updateJobError,
} from '@perpay-web/fintech/actions/entities/jobs';

export function getJobs(action$, state$, { get }) {
    return action$.pipe(
        ofType(BACKEND_FETCH_JOBS),
        exhaustMap(() => emitOrTimeout(get(`${JOBS_ENDPOINT}?status=primary`))),

        mergeMap((results) => {
            const normalized = normalize(results.response, [job]);
            return [replaceJobs(normalized.entities[JOBS])];
        }),
        handleErrorMessageWithFallback((error) => [fetchJobsError(error)]),
    );
}

export function fetchJobsForDataModule(action$, state$, { get }) {
    return action$.pipe(
        ofType(fetchJobsForDataModuleAction().type),
        exhaustMap(() => emitOrTimeout(get(`${JOBS_ENDPOINT}?status=primary`))),
        mergeMap((results) => {
            // we expect 1 primary job to be returned
            const primary = results.response[0];
            return [fetchJobsSuccess({ primary })];
        }),
        handleErrorMessageWithFallback((error) => [
            fetchJobsErrorForDataModule(error),
        ]),
    );
}

export function checkoutUpdateEmployeeID(action$, state$, { patch }) {
    return action$.pipe(
        ofType(BACKEND_CHECKOUT_UPDATE_EMPLOYEE_ID),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const primaryJobUUID = getPrimaryJobUUID(state);
            const requestBody = action.payload;
            return patch(`${JOBS_ENDPOINT}${primaryJobUUID}/`, requestBody);
        }),
        mergeMap(() => [checkoutNextStep()]),
        handleErrorMessageWithFallback((error) => [
            checkoutUpdateEIDError(error),
        ]),
    );
}

export function createPrimaryJob(action$, state$, { post }) {
    return action$.pipe(
        ofType(createPrimaryJobAction().type),
        exhaustMap((action) => {
            const { payload } = action;
            return post(JOBS_ENDPOINT, {
                company: {
                    uuid: payload.uuid,
                },
                estimatedNetPay: payload.estimatedNetPay,
                payCycle: payload.payCycle,
                selfOnboardingData: payload.selfOnboardingData,
            });
        }),
        mergeMap((results) => [createPrimaryJobSuccess(results.response)]),
        handleErrorMessageWithFallback((error) => [
            createPrimaryJobError(error),
        ]),
    );
}

export function updateJob(action$, state$, { patch }) {
    return action$.pipe(
        ofType(updateJobAction().type),
        exhaustMap((action) => {
            const { payload } = action;
            const { jobUuid } = payload;

            return patch(`${JOBS_ENDPOINT}${jobUuid}/`, {
                company: {
                    uuid: payload.companyUuid,
                    name: payload.companyName,
                },
                estimatedNetPay: payload.estimatedNetPay,
                payCycle: payload.payCycle,
            });
        }),
        mergeMap((results) => [updateJobSuccess(results.response)]),
        handleErrorMessageWithFallback((error) => [updateJobError(error)]),
    );
}
