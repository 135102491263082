import { createDataModule } from '@perpay-web/data-module/createDataModule';
import {
    fetchMarketplaceDashboardSummary,
    fetchMarketplaceDashboardSummarySuccess,
    fetchMarketplaceDashboardSummaryError,
    fetchMarketplaceDashboardSummaryReset,
} from '@perpay-web/fintech/actions/entities/marketplaceDashboardSummary';

const FetchMarketplaceDashboardSummaryDataModule = createDataModule({
    dataRequest: fetchMarketplaceDashboardSummary,
    dataSuccess: fetchMarketplaceDashboardSummarySuccess,
    dataError: fetchMarketplaceDashboardSummaryError,
    dataReset: fetchMarketplaceDashboardSummaryReset,
});

// TODO: Remove use of underscore_case once backend change has been made
const getRoot = (state) => state.dataModules.fetchMarketplaceDashboardSummary;
const initialValue = {
    available_spending_limit: 0,
    availableSpendingLimit: 0,
    notification: '',
    isSpendingLimitEstimate: true,
};

export const fetchMarketplaceDashboardSummaryDataModule =
    FetchMarketplaceDashboardSummaryDataModule({
        getRoot,
        initialValue,
    });

export const getSpendingLimit = (state) =>
    fetchMarketplaceDashboardSummaryDataModule.getData(state)
        .available_spending_limit ||
    fetchMarketplaceDashboardSummaryDataModule.getData(state)
        .availableSpendingLimit ||
    '--';

export const getNotification = (state) =>
    fetchMarketplaceDashboardSummaryDataModule.getData(state).notification ||
    '';

export const getIsSpendingLimitEstimate = (state) =>
    fetchMarketplaceDashboardSummaryDataModule.getData(state)
        .isSpendingLimitEstimate || true;
