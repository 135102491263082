import { Outlet } from 'react-router-dom';
import { getClassName } from '@perpay-web/utils/domUtils';
import { useMount } from '@perpay-web/hooks/useMount';
import { useFullBottomNavRender } from '@perpay-web/hooks/useFullBottomNavContext';
import { useAppTitle } from '@perpay-web/hooks/useAppTitle';
import BorrowerHeaderContainer from '@perpay-web/fintech/components/containers/BorrowerHeaderContainer';
import SupportToolContainer from '@perpay-web/fintech/components/containers/SupportToolContainer';
import NotificationsContainer from '@perpay-web/fintech/components/containers/NotificationsContainer';
import { useTrackLocationChange } from '@perpay-web/hooks/useTrackLocationChange';

import styles from './App.scss';

const App = ({ onMount }) => {
    useMount(onMount);
    useAppTitle();
    const renderedBottomNav = useFullBottomNavRender();

    useTrackLocationChange();

    return (
        <div
            className={getClassName(
                styles.app,
                renderedBottomNav ? styles['has-footer'] : '',
            )}
        >
            <NotificationsContainer />
            <BorrowerHeaderContainer />
            <div className={styles.app__container}>
                <Outlet />
            </div>
            {renderedBottomNav}
            <SupportToolContainer className={styles['support-tool']} />
        </div>
    );
};

export default App;
