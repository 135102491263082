import { ofType } from 'redux-observable';
import {
    mergeMap,
    exhaustMap,
    switchMap,
    withLatestFrom,
    map,
} from 'rxjs/operators';

import { reduxStepsSetStep } from '@perpay-web/hooks/useReduxSteps';
import { standardizeError } from '@perpay-web/utils/errorHandlers';
import { replace } from '@perpay-web/services/router';
import { handleError } from '@perpay-web/observable/operators/handleError';
import { authentication } from '@perpay-web/fintech/settings/singletons';
import { installSegmentMiddleware } from '@perpay-web/fintech/utils/analyticsUtils';
import {
    ERROR_FORBIDDEN,
    ERROR_CONFLICT,
} from '@perpay-web/constants/httpStatusCodes';
import {
    partnerOnboardingSignup as partnerOnboardingSignupAction,
    partnerOnboardingSignupSuccess,
    partnerOnboardingSignupError,
    verifyPartnerOnboardingCode as verifyPartnerOnboardingCodeAction,
    verifyPartnerOnboardingCodeSuccess,
    partnerOnboardingSubmitCardApplication as partnerOnboardingSubmitCardApplicationAction,
    partnerOnboardingSubmitCardApplicationSuccess,
    partnerOnboardingSubmitCardApplicationError,
    createPassword as createPasswordAction,
    createPasswordError,
} from '@perpay-web/fintech/actions/entities/partnerOnboarding';
import { analyticsIdentifyUser } from '@perpay-web/fintech/actions/analytics/userInfo';
import { getUserInfo } from '@perpay-web/fintech/selectors/entities/userInfo';
import { getAnalyticsIdentity } from '@perpay-web/utils/tokenUtils';
import {
    replaceAuthTokens,
    refreshAccessToken,
    partnerOnboardingRedirectExistingUser,
} from '@perpay-web/fintech/actions/authentication';
import { partnerOnboardingSignupIneligibleStateModalSetIsModalOpen } from '@perpay-web/fintech/hooks/usePartnerOnboardingSignupIneligibleStateModalContext';
import {
    partnerOnboardingExistingUserModalSetExistingUserFirstName,
    partnerOnboardingExistingUserModalSetIsModalOpen,
    partnerOnboardingExistingUserModalSetMatchMethod,
} from '@perpay-web/fintech/hooks/usePartnerOnboardingExistingUserModalContext';
import { partnerHostedApplicationStartPolling } from '@perpay-web/fintech/actions/ui/partnerHostedCardApplication';
import {
    analyticsSignupSuccess,
    analyticsOnboardingAddPhoneNumber,
    analyticsOnboardingAddCompany,
    analyticsOnboardingAddNetPay,
} from '@perpay-web/fintech/actions/ui/signup';
import { paths } from '@perpay-web/fintech/props/appPaths';
import {
    VERIFY_PARTNER_ONBOARDING_CODE_ENDPOINT,
    PARTNER_ONBOARDING_SUBMIT_CARD_APPLICATION_ENDPOINT,
    SET_PASSWORD_ENDPOINT,
} from '@perpay-web/fintech/constants/urls';
import * as partnerOnboardingSteps from '@perpay-web/fintech/constants/steps/cardPartnerOnboardingSteps';
import { handleErrorMessageWithFallback } from '@perpay-web/observable/operators/handleErrorMessageWithFallback';
import { getPartnerNameFromCode } from '@perpay-web/fintech/utils/partnerOnboardingUtils';
import { SIGNUP_FLOW_PARTNER_ONBOARDING } from '@perpay-web/fintech/constants/signupFlowTypes';
import { SELF_ONBOARDED_COMPANY_NAME } from '@perpay-web/fintech/constants/companies';

export const partnerOnboardingSignup = (action$, state$) =>
    action$.pipe(
        ofType(partnerOnboardingSignupAction().type),
        exhaustMap((action) =>
            authentication
                .partnerHostedSignup(action.payload)
                .then((results) =>
                    installSegmentMiddleware().then(() => results),
                ),
        ),
        withLatestFrom(state$),
        mergeMap(([results, state]) => {
            const { tokens: unusedTokens, ...rest } = results;

            const decodedTokens = authentication.getDecodedTokens();
            const identityAction = getAnalyticsIdentity(
                decodedTokens,
                getUserInfo(state),
            );
            const partnerCode = authentication.getPartnerOnboardedCode();
            const partnerName = getPartnerNameFromCode(partnerCode);
            const uuid = authentication.getUserUuid();
            replace(
                `${paths.partnerOnboardingApplication.path}/${window.location.search}`,
            );
            return [
                analyticsIdentifyUser(identityAction),
                replaceAuthTokens(decodedTokens),
                partnerOnboardingSignupSuccess(rest),
                analyticsSignupSuccess({
                    ...rest,
                    uuid,
                    signupFlow: SIGNUP_FLOW_PARTNER_ONBOARDING,
                    partner: partnerName,
                }),
                analyticsOnboardingAddPhoneNumber({
                    signupFlow: SIGNUP_FLOW_PARTNER_ONBOARDING,
                    partner: partnerName,
                }),
            ];
        }),
        handleError((error) => {
            const returnActions = [partnerOnboardingSignupError(error)];

            if (error.status === ERROR_CONFLICT) {
                const {
                    message,
                    name: firstName,
                    method,
                    mfaRequired,
                } = error.response;
                const isMfaRequired = mfaRequired ? mfaRequired[0] : false;

                returnActions.push(partnerOnboardingRedirectExistingUser());

                if (
                    (method && method === 'phone') ||
                    message[0].includes('Phone') ||
                    isMfaRequired
                ) {
                    replace(paths.login.path);
                    return returnActions;
                }
                returnActions.push(
                    partnerOnboardingExistingUserModalSetIsModalOpen(true),
                );
                if (firstName) {
                    returnActions.push(
                        partnerOnboardingExistingUserModalSetExistingUserFirstName(
                            firstName,
                        ),
                    );
                }
                if (method) {
                    returnActions.push(
                        partnerOnboardingExistingUserModalSetMatchMethod(
                            method,
                        ),
                    );
                }
            } else if (error.status === ERROR_FORBIDDEN) {
                returnActions.push(
                    partnerOnboardingSignupIneligibleStateModalSetIsModalOpen(
                        true,
                    ),
                );
            }
            return returnActions;
        }),
    );

export const verifyPartnerOnboardingCode = (action$, state$, { jsonFetch }) =>
    action$.pipe(
        ofType(verifyPartnerOnboardingCodeAction().type),
        switchMap((action) => {
            const { partnerCode } = action.payload;
            const endpoint = `${VERIFY_PARTNER_ONBOARDING_CODE_ENDPOINT}?partner_code=${partnerCode}`;
            return jsonFetch(endpoint).then((results) => [
                results,
                partnerCode,
            ]);
        }),
        mergeMap(([results, partnerCode]) => {
            const isVerified = results.verified_code;
            if (!isVerified) {
                replace(paths.signUp.path);
                return [];
            }

            return [verifyPartnerOnboardingCodeSuccess(partnerCode)];
        }),
        handleError(() => {
            replace(paths.signUp.path);
        }),
    );

export const partnerOnboardingSubmitCardApplication = (
    action$,
    state$,
    { post },
) =>
    action$.pipe(
        ofType(partnerOnboardingSubmitCardApplicationAction().type),
        switchMap((action) =>
            post(
                PARTNER_ONBOARDING_SUBMIT_CARD_APPLICATION_ENDPOINT,
                action.payload,
            ).pipe(
                map((results) => {
                    const isSelfOnboarded = Object.keys(
                        action.payload.jobInfo.selfOnboardingData,
                    ).length;
                    const companyName = isSelfOnboarded
                        ? SELF_ONBOARDED_COMPANY_NAME
                        : action.payload.jobInfo.company.company;
                    return [results, companyName];
                }),
            ),
        ),
        mergeMap(([results, companyName]) => {
            const cardAccountApplication = results.response;
            const partnerCode = authentication.getPartnerOnboardedCode();
            const partner = getPartnerNameFromCode(partnerCode);
            return [
                partnerOnboardingSubmitCardApplicationSuccess(
                    cardAccountApplication,
                ),
                reduxStepsSetStep(
                    Object.values(partnerOnboardingSteps),
                    partnerOnboardingSteps.SUBMITTING_STEP,
                ),
                partnerHostedApplicationStartPolling(
                    cardAccountApplication.uuid,
                ),
                analyticsOnboardingAddCompany({
                    companyName,
                    signupFlow: SIGNUP_FLOW_PARTNER_ONBOARDING,
                    partner,
                }),
                analyticsOnboardingAddNetPay({
                    signupFlow: SIGNUP_FLOW_PARTNER_ONBOARDING,
                    partner,
                }),
            ];
        }),
        handleError((error) => {
            if (error.status >= 500) {
                return [
                    reduxStepsSetStep(
                        Object.values(partnerOnboardingSteps),
                        partnerOnboardingSteps.APPLICATION_ERROR_STEP,
                    ),
                ];
            }
            const standardizedError = standardizeError(error);
            return [
                partnerOnboardingSubmitCardApplicationError(standardizedError),
            ];
        }),
    );

export const createPassword = (action$, state$, { post }) =>
    action$.pipe(
        ofType(createPasswordAction.type),
        exhaustMap((action) => {
            const payload = {
                password: action.payload.password,
            };

            return post(SET_PASSWORD_ENDPOINT, payload).pipe(
                map((results) => [results, action]),
            );
        }),
        mergeMap(([, action]) => {
            if (action.payload.dashboardRedirect) {
                replace(`${paths.dashboard.path}?PHO_complete=true`);
            }

            return [refreshAccessToken()];
        }),
        handleErrorMessageWithFallback((error) => [createPasswordError(error)]),
    );
