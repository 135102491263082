import { SHOP_PREFIX } from '@perpay-web/constants/paths';

export const HOME = '/';
export const LOGOUT = '/logout/';
export const DASHBOARD = '/dashboard/';
export const ORDERS = '/orders/';
export const MARKETPLACE_DETAILS = '/perpay-marketplace/';
export const MARKETPLACE_ACTIVITY = '/perpay-marketplace/activity';
export const MARKETPLACE_LEARN = '/perpay-marketplace/learn';
export const PROFILE = '/dashboard/profile/';
export const EDIT_INFO = '/dashboard/profile/edit_info/';
export const EDIT_EMPLOYMENT = '/dashboard/profile/edit_employment/';
export const LOGIN = '/login/';
export const RESET_PASSWORD = '/login/reset_password/';
export const SIGN_UP_BASE = '/sign_up';
export const SIGN_UP = `${SIGN_UP_BASE}/`;
export const CHECKOUT = '/checkout/';
export const PAYMENT_INSTRUCTIONS = '/payment_instructions/';
export const REFERRALS = '/referrals/';
export const PAYMENT_PORTAL = '/payment_portal/';
export const RECURRING_PAYMENTS = '/payment_portal/recurring/';
export const ONE_TIME_PAYMENTS = '/payment_portal/one_time/';
export const PAYMENT_SETUP = '/set_up_payments/';
export const IDENTITY_VERIFICATION = '/identity/';
export const BANK_CREATE = '/bank/';
export const BANK_VERIFY = '/bank/verify/';
export const EXTERNAL_CARD_CREATE = '/card/';
export const UPLOAD_PAYSTUB = '/upload_paystub/';
export const CREDIT_REPORTING = '/credit_building/';
export const CREDIT_MONITORING = '/plus_summary/';
export const CREDIT_MONITORING_DETAILS = `${CREDIT_MONITORING}details`;
export const PERPAY_PLUS = '/plus/';
export const SHOP = '/shop/';
export const WITHDRAWAL = '/dashboard/profile/withdrawal';
export const BANK_VERIFY_SELECT = '/dashboard/profile/bank/verify';
export const CARD_INVITE = '/sign_up/card_invite/';
export const CARD_ACTIVATE = '/activate';
export const CARD_DETAILS = '/perpay-card/';
export const CARD_ONBOARDING = '/perpay-card/apply/';
export const CARD_LEARN = '/perpay-card/learn/';
export const CARD_PAYMENT = '/perpay-card/payment/';
export const CARD_ACTIVITY = '/perpay-card/activity/';
export const CARD_STATEMENTS = '/perpay-card/statements/';
export const CARD_SETTINGS = '/perpay-card/settings';
export const CARD_PARTNER_ONBOARDING_INVITATION = '/perpay-card/invitation';
export const PROFILE_BANKS_ID = 'banks';
export const PROFILE_EXTERNAL_CARDS_ID = 'cards';
export const DIRECT_DEPOSIT = '/direct-deposit/';
export const UPDATE_DIRECT_DEPOSIT = '/direct-deposit/update';
export const UPDATE_DIRECT_DEPOSIT_CLI = '/direct-deposit/credit-limit';
export const REWARDS_AND_CREDITS = '/rewards-and-credits';
export const REWARDS_AND_CREDITS_DETAILS = '/rewards-and-credits/details';
export const REWARD_HISTORY = '/rewards-and-credits/details/reward-history';
export const DIRECT_DEPOSIT_ACTIVITY = '/direct-deposit/activity';

/* SHOP PATHS */
export const SHOP_HOME = `${SHOP_PREFIX}`;
export const SHOP_SEARCH_PATH = `${SHOP_PREFIX}/search?query=:query`;
export const SHOP_AUDIO_PATH = `${SHOP_PREFIX}/category/electronics/personal-audio-video`;
export const SHOP_HANDBAGS_PATH = `${SHOP_PREFIX}/category/fashion-beauty/women/handbags-wallets`;
export const SHOP_GAMING_PATH = `${SHOP_PREFIX}/category/electronics/video-games`;
export const SHOP_HOUSEHOLD_BASICS_PATH = `${SHOP_PREFIX}/category/home/basics`;
export const SHOP_KIDS_PATH = `${SHOP_PREFIX}/category/baby-kids`;
export const SHOP_HOUSEWARES_PATH = `${SHOP_PREFIX}/category/home/housewares`;
export const SHOP_KITCHEN_PATH = `${SHOP_PREFIX}/category/home/kitchen-bath`;
export const SHOP_GIFTS_FOR_EVERYONE_PATH = `${SHOP_PREFIX}/featured/gifts-for-everyone`;
export const SHOP_ELECTRONICS_PATH = `${SHOP_PREFIX}/category/electronics`;
export const SHOP_AUTO_PATH = `${SHOP_PREFIX}/category/auto`;
export const SHOP_NEW_PRODUCTS_PATH = `${SHOP_PREFIX}/new-arrivals`;
export const SHOP_FASHION_PATH = `${SHOP_PREFIX}/category/fashion-beauty`;
export const SHOP_PET_PATH = `${SHOP_PREFIX}/category/pet`;
export const SHOP_HOME_PATH = `${SHOP_PREFIX}/category/home`;
export const SHOP_LIFESTYLE_PATH = `${SHOP_PREFIX}/category/lifestyle`;
