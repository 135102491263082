import { lazy } from 'react';
import PropTypes from 'prop-types';
import { useMountAndUnmount } from '@perpay-web/hooks/useMountAndUnmount';
import WarningLoaderCircle from '@perpay-web/components/base/WarningLoaderCircle/WarningLoaderCircle';
import { withLoader } from '@perpay-web/components/higher-order/WithLoader/WithLoader';
import { importCardOnboardingContainer } from '@perpay-web/fintech/utils/imports/importCardOnboardingContainer';
import { importCardOnboardingMVP2Container } from '@perpay-web/fintech/utils/imports/importCardOnboardingMVP2Container';
import { importCardOnboardingMetalContainer } from '@perpay-web/fintech/utils/imports/importCardOnboardingMetalContainer';
import PhoExistingUserNotification from '@perpay-web/fintech/components/base/Notifications/PhoExistingUserNotification';
import { CARD_SIGNUP_MVP_IS_LAUNCHED } from '@perpay-web/fintech/constants/flags';

const CardOnboardingMVP2ContainerWithLoader = withLoader(
    lazy(importCardOnboardingMVP2Container),
);
const CardOnboardingContainerWithLoader = withLoader(
    lazy(importCardOnboardingContainer),
);
const CardOnboardingMetalContainerWithLoader = withLoader(
    lazy(importCardOnboardingMetalContainer),
);

const CardOnboardingSwitcher = ({
    onMount,
    onUnmount,
    isLoading,
    isFirstTimeBorrower,
    isCardFirstUser,
    isMetalCard,
    isPartnerOnboarded,
    ...rest
}) => {
    useMountAndUnmount(() => {
        onMount();
        return () => onUnmount();
    });

    if (isLoading) {
        return <WarningLoaderCircle />;
    }

    if (isPartnerOnboarded) {
        return <PhoExistingUserNotification />;
    }
    // TODO: Update the condition once the card signup is launched
    if (
        isFirstTimeBorrower ||
        (CARD_SIGNUP_MVP_IS_LAUNCHED && isCardFirstUser)
    ) {
        if (isMetalCard) {
            return <CardOnboardingMetalContainerWithLoader {...rest} />;
        }
        return <CardOnboardingMVP2ContainerWithLoader {...rest} />;
    }
    return <CardOnboardingContainerWithLoader {...rest} />;
};

CardOnboardingSwitcher.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isFirstTimeBorrower: PropTypes.bool.isRequired,
    onMount: PropTypes.func.isRequired,
    onUnmount: PropTypes.func.isRequired,
    isCardFirstUser: PropTypes.bool.isRequired,
    isMetalCard: PropTypes.bool.isRequired,
    isPartnerOnboarded: PropTypes.bool.isRequired,
};

export default CardOnboardingSwitcher;
